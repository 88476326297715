@import (reference) '_helpers.less';

.btn-normal {
	border-radius: 2px;
}

.btn-clear {
  border: 0;
  border-radius: 0;
  display: inline-block;
  background: none;
}

.btn-navy-blue {
	background: @color-navy-blue;
	color: @color-white;

	&:hover {
		background: @color-navy-blue-dark;
	}
}

.btn-sky-blue {
	background: @color-sky-blue;
	color: @color-white;

	&:hover {
		background: @color-sky-blue-dark;
	}
}

//                       btn-gradient

.btn-gradient-pink {
	background: #e04281;
	background: linear-gradient(to right, #e04281 0%, #fc6787 100%);
	background-size: 200% auto;
	transition: 0.5s;
	border: 0;

	&:hover {
		color: #ffffff;
		background-position: right center;
	}

	&:focus,
	&:active {
		background: linear-gradient(to right, #e04281 0%, #fc6787 100%) !important;
		color: #ffffff;
		background-position: right center;
	}

	&:active:not(:disabled) {
		background: linear-gradient(to right, #e04281 0%, #fc6787 100%) !important;
		color: #ffffff;
		background-position: right center;
	}

	&_type {
		&_shaddow {
			box-shadow: 0 17px 25px #fe195e57;
		}
	}
}

.btn-gradient-blue {
	background: #1b37e9;
	background: linear-gradient(12deg, #1b37e9 0%, #9600ff 78%);
	background-size: 100% auto;
	background-position: center center;
	transition: 0.5s;
	border: 0;
	color: #fff;

	&:hover {
		color: #fff;
		background-size: 300% auto;
		background-position: right center;
	}

	&:focus,
	&:active,
	&:active:not(:disabled) {
		background: linear-gradient(12deg, #1b37e9 0%, #9600ff 78%) !important;
		color: #ffffff;
		background-size: 200% auto;
		background-position: right center;
	}

	&_type {
		&_shaddow {
			box-shadow: 0px 13px 13px 0px rgba(27, 55, 233, 0.26);
		}
	}
}


.btn-gradient-orange {
	background: #FF8F00;
	background: linear-gradient(79.62deg, #E6A556 2.78%, #FF8F00 85.65%);
	background-size: 100% auto;
	background-position: center center;
	//transition: 0.5s;
	transition:  all 0.5s ease-out;
	border: 0;
	color: @color-white;

	&:hover {
		color: @color-white;
		background-size: 300% auto;
		background-position: right center;
		opacity: .7;

		&.btn-gradient-orange_type {
			&_shaddow {
				box-shadow: 0 14px 14px rgba(255, 143, 0, 0.3);
			}
		}
	}

	&:focus,
	&:active,
	&:active:not(:disabled) {
		background: linear-gradient(79.62deg, #E6A556 2.78%, #FF8F00 85.65%) !important;
		color: @color-white;
		background-size: 200% auto;
		background-position: right center;
	}

	&_type {
		&_shaddow {
			box-shadow: 0 14px 14px rgba(255, 143, 0, 0.2);
		}
	}
}

//                         btn-outline

.btn-outline-orange {
	color: #161f23;
	border-color: #F99F34;
	background-color: transparent;
	transition: all 0.2s;

	&:hover {
		background: linear-gradient(79.62deg, #E6A556 2.78%, #F99F34 100%) !important;
		background-color: #F99F34;
		background-size: 200% auto;
		background-position: 95% center;
		border-color: transparent;
	}

	&:focus,
	&:active,
	&:active:not(:disabled):not(.disabled),
	&:not(:disabled):not(.disabled):active{
		border-color: #F99F34;
	}
}

.btn-outline-success {
	color: #161f23;
	border-color: #53e190;
	background-color: transparent;
	transition: all 0.2s;

	&:hover {
		background: linear-gradient(to right, #41c7af 0%, #54e38e 100%);
		background-color: #53e190;
		background-size: 200% auto;
		background-position: 95% center;
		border-color: transparent;
	}

	&:disabled,
	&.disabled {
		border: 2px solid #45BE8C;

		&:hover {
			color: #161f23;
			cursor: default;
			background: transparent;

			* {
				color: #161f23 !important;
			}
		}
	}
}

.btn-outline-white {
	border-color: @color-white;
	color: @color-white;

	&:hover,
	&:active,
	&:focus {
		background: transparent;
		border-color: @color-white;
		color: @color-white;
	}
}
