@import (reference) "_helpers.less";
@import (reference) "_breakpoint.less";

/* Datepicker Trigger */
.datepicker-trigger {
    @bl: ~'.datepicker-trigger';

    .asd__month-year-select {
        color: @color-default;
    }

    .asd__wrapper--datepicker-open {
        z-index: 1000;
    }

    .datepicker-open {
        border: 1px solid @color-border;
        box-shadow: 0 0 0 0.2rem rgba(69, 190, 140, 0.3) !important;
    }

    &__result {
        cursor: pointer;
    }

    &__icon {
        font-size: 19px;
        position: absolute;
        left: 0;
        top: 0;
        transform: translate(10px, 7px);
        z-index: 10;
    }

    &__text {
        font-size: 14px;
        color: @color-default !important;
        background: none;
        text-align: left;
        padding-left: 32px;
        border-radius: 4px !important;

        &_type {

            &_is-placeholder {
                color: @color-blue-dark !important;
            }
        }
    }
}