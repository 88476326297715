@import (reference) "_helpers.less";
@import (reference) "_breakpoint.less";

.main {
  @bl: ~".main";

  &__banner-footer {
    background: linear-gradient(90deg, #313385 0%, #7133F4 100%), #595AF9;

    .md({
      text-align: center;
      padding-top: 35px;
      padding-bottom: 35px;
    });
  }

  &__banner-img {
    max-width: 178px;
  }

  &__banner-title {
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 29px;

    .md({
      font-size: 22px;
      line-height: 27px;
    });
  }

  &__banner-subtitle {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    color: @color-perano;

    .md({
      font-size: 16px;
      line-height: 20px;
    });
  }

  &__banner-btn {
    border-radius: 4px;
    border-width: 1px;
  }
}
