@import (reference) "_helpers.less";
@import (reference) "_breakpoint.less";

/* Header */
.header {
    @bl: ~'.header';

    padding: 0;
    background: #1f2e33;
    transition: all 0.2s linear;
    position: relative;
    z-index: 1000;
    flex-wrap: wrap;
    overflow: visible;

    .container {
        .md({
            padding: 0;
            max-width: 100%;
        });
    }

    &_type {
        &_open {
            .md({
                box-shadow:  0 10px 40px rgba(0, 0, 0, 0.3);
                padding-bottom: 0;
            })
        }
    }

    &__inner {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;

        .md({
            flex-wrap: wrap;
        });

        .lg({
            position: relative;
            display: flex;
            flex: 1;
            justify-content: space-between;
        });
    }

    &__inner-tha {
        @{bl}__toggle {
            .xs({
                margin-left: 0;
            })
        }

    }

    &__inner-msa {
        @{bl}__toggle {
            .xs({
                margin-left: 10px;
            })
        }

    }

    &__inner-content {

        .md({
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            position: relative;
            flex-wrap: wrap;
            padding-top: 16px;
            padding-bottom: 17px;
        });
    }

    &__exchange {
        @{bl}__inner-content {
            .md({
                background: #28393f url("/i/header_exchange_md.jpg") no-repeat;
                background-position-x: left;
                background-size: 100% 100%;
            });
        }
    }

    &__margin {
        @{bl}__inner-content {
            .md({
                background: #28393f url("/i/header_margin_md.jpg") no-repeat 100% 100%;
                background-position-x: left;
                background-size: 100% 100%;
            });
        }
    }

    &__logo {
        margin: 20px auto 20px 0;

        .md({
            padding-left: 1.5rem;
        });
    }

    &__entry {

        @{bl}__logo {

            .md({
                margin: 0;
            });
        }
    }

    &__logo-image {
        width: 120px;

        &_type {
            &_is-client {
                width: 112px;
            }
        }
    }

    &__collapse {
        transition: all 0.25s ease-in-out;

        .md({
            margin-bottom: -1.5rem;
            border-top: 1px solid #153f52;

            &.collapse,
            &.collapsing {
                margin-top: 1.6rem;
            }
        });
    }

    &__menus {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        flex: 1;

        .md({
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
        });
    }

    &__menu {

        li {
            display: flex;
            align-items: center;
            text-align: left;

            .md({
                background: linear-gradient(0deg, #1F2E33, #1F2E33);
            });

            &:first-child a {
                margin-left: 0;
            }

            + li {
                @{bl}__link {
                    .md({
                        border-bottom: 1px solid #172428;
                    });

                    &.active {
                        .md({
                            border-bottom: 3px solid #41c7af
                        });

                        + .header__btns-is-mobile {
                            position: relative;
                            .md({

                                &:after {

                                    border-bottom: 3px solid #41c7af;
                                    content: "";
                                    position: absolute;
                                    width: 100%;
                                    display: block;
                                    bottom: -12px;
                                }
                            });
                        }
                    }
                }
            }

        }

        .lg-mf({
            width: 100%;
            display: flex;
            justify-content: flex-start;
        });


        .xl-mf({
            flex: auto;
            width: 100%;
        });

        .md({
            margin-right: 0;
            margin-left: 0;
            padding: 0 !important;
            width: 100% !important;
            order: 2;
            justify-content: normal;
            flex-direction: column !important;

            .header_type_open & {
                background-color: #2a3c42;
            }
            .nav-item.dropdown {
                flex-wrap: wrap;
            }
            .dropdown-menu.show,
            .dropdown-menu {
                transform: none;
                width: 100%;
                max-width: 100%;
                position: relative;
                border-radius: 0;
                box-shadow: none;
                left: auto;
                top: auto;
                bottom: auto;
                right: auto;
                padding: 0;
                border: 0;
                margin: 0;

                .dropdown-item {
                    width: 100%;
                    text-align: center;
                    padding: 1rem 0;
                    font-size: 16px;
                    line-height: 20px;
                    font-weight: 500;
                    color: #fff;
                    background-color: #23363d;
                    border-top: 1px solid #2f434a;

                    .md({
                        background: linear-gradient(0deg, #172428, #172428);
                        padding-left: 47px;
                        text-align: left;
                        border-top: 1px solid transparent;
                        padding-top: 10px;
                        padding-bottom: 10px;
                    });

                    &.active {
                        .md({
                            background: #41c7af;
                        });
                    }

                    + .dropdown-item {
                        .md({
                            padding-top: 10px;
                        })
                    }

                }
            }
        });

        &_type {
            &_main {
                padding-left: 40px;

                .header__link ~ .dropdown-menu {
                    .lg-mf({
                        transform: translateY(-18px);
                    });
                }
            }
        }
    }

    &__menu-badge {
        border-radius: 50%;
        font-size: 0;
        width: 6px;
        height: 6px;
        display: inline-block;
        line-height: 0;
        position: absolute;
        right: 0;
        top: 0;
        transform: translate(-16px, 11px);

        .xs({
            transform: translate(-35px, 11px);
        });

        &.badge-warning {
            background-color: #fcb50b;
        }
    }

    &__link {
        width: 100%;
        margin: 0 0.6rem;
        color: #99c3d1;
        text-align: center;
        font: 600 14px Montserrat, "Source Sans Pro", sans-serif;
        transition: color 0.1s linear;
        padding-top: 28px;
        padding-bottom: 29px;

        &:focus {
            color: #ffffff;
        }
        &:hover,
        &:active {
            color: #00ff9c;
        }

        &.active {
            .lg({
                border-bottom: 3px solid #00d3a2;
            });

            .lg-mf({
                padding-bottom: 26px;
                border-bottom-style: solid;
                border-bottom-width: 3px;
                border-image: linear-gradient(to right, #41c7af 0%, #54e38e 100%);
                border-image-slice: 1;
            });
        }

        &.dropdown-toggle {
            &:after {
                position: relative;
                top: 2px;
                transition: transform 0.1s linear;
            }
            &[aria-expanded="true"] {
                color: @color-hover;

                &:after {
                    transform: rotate(180deg);
                }
            }
        }

        .xl-mf({
            margin: 0 1.2rem;
        });

        .lg-mf({
            white-space: nowrap;
        });

        .md({
            width: 100% !important;
            margin: 0;
            font-size: 16px;
            color: @color-white;
            flex-wrap: wrap;
            float: left;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 1.4rem 20px !important;

            .nav-item:first-child & {
                border-top: none;
            }

            .action-link__label {
                right: 7px;
            }
        });
    }

    .btn-outline-secondary {
        color: @color-white;

        &:focus {
            color: @color-white;
            background: none;
        }
        &:hover,
        &:active:not(:disabled) {
            background-color: @color-white;
            border-color: @color-white;
            color: #000000;
        }
    }

    .btn-gradient-04 {
        background: #41c7af;
        background: linear-gradient(to right, #41c7af 0%, #54e38e 100%);
        background-size: 200% auto;

        &:hover {
            color: @color-white;
            background-position: right center;
        }
    }

    &__user_menu {
        white-space: nowrap;

        a {
            color: @color-white;
        }
    }

    &__user-icon {
        font-size: 24px;
        color: @color-white;
        transition: all .2s linear;

        .fi {
            transform: translateY(0);
        }
    }

    &__toggler {
        color: @color-primary;
        font-size: 32px;
        margin-top: 1px;
        transition: all 0.2s linear;
        width: 55px;
        height: 42px;

        .la-close {
            display: inline-block;
            color: @color-white;
        }
        .la-bars {
            display: none;
            color: @color-white;
        }
        &[aria-expanded="false"] {
            color: #688893;

            .la-close {
                display: none;
            }
            .la-bars {
                display: inline-block;
            }
        }

        .md({
            display: block !important;
        })
    }

    &__btns {
        .md({
            margin-bottom: 12px;
        });

        .xs({
            width: 100%;
            position: relative;
            padding: 0 5px;
        });

        .btn {
            margin-left: 12px;

            .md({
                width: 140px;
            });

            .xs({
                width: 100%;
                margin: 0 0 5px 0;
                transition: background-position 0.2s linear;
            });

            .xs({
                + .btn {
                    margin-top: 10px;
                }
            });

        }

        @{bl}__button-exchange,
        @{bl}__button-tradein {
            width: auto;

            .xs({
                width: 100%;
            })
        }
    }

    &__button-exchange {
        height: 38px;
        color: #fff;
    }

    &__button-tradein {
        height: 38px;
        color: #fff;

        &_type {
            &_old {
                .sm({
                    margin: auto !important;
                });
            }
        }
    }

    &__user-dropdown-button {
        cursor: pointer;
        height: 44px;
        text-align: center;
        line-height: 44px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #ffffff;
        font-size: 14px;
        font-weight: 600;
        line-height: normal;

        &:focus {
            color: #71919c;
        }
        &:hover,
        &:active {
            color: #54e38e;

            @{bl}__user-icon {
                color: @color-primary;
            }
        }

        .md({
            display: none !important;
        });
    }

    &__user-dropdown-wrapper {
        .md({
            width: 100%;
            order: 2;
            padding: 10px 0;
        });

        .dropdown-menu.show,
        .dropdown-menu {
            .md({
                display: flex!important;
                flex-direction: column;
                width: 100% !important;
                opacity: 1;
                position: relative;
                left: 0 !important;
                right: 0 !important;
                top: 0;
                bottom: auto;
                visibility: visible;
                transform: none !important;
                max-width: 100%;
                border-radius: 0;
                box-shadow: none;
                padding: 0;
                border: 0;
                margin: 0;
                background: #2f434a;
            });
        }
        .dropdown-divider {
            border-top: 1px solid #e2e8ea;
            position: relative;
            margin: 6px 0;

            .md({
                display: none;
            });
        }
        .dropdown-header {
            font-size: 15px;
            line-height: 20px;
            font-weight: 600;
            padding: 6px 20px;

            .md({
                padding: 15px 0;
                width: 100%;
                background: #2f434a;
                color: #688893;
                text-align: center;
                font-size: 18px;
            });
        }
        .dropdown-item {
            padding: 6px 20px;
            font-size: 12px;
            line-height: 16px;
            font-weight: 500;
            color: #454f59;

            .md({
                padding: 15px 0;
                width: 100%;
                background: #2f434a;
                color: #ffffff;
                text-align: center;
                font-size: 16px;
                border-top: 1px solid #39525A;
            });
        }
    }

    &__submenu-wrapper {
        background: #1f2e33;
        width: 100%;
    }

    &__submenu {
        flex-direction: row;

        .header__link {
            padding: 17px 0 16px;
            white-space: nowrap;
            color: #fff;

            &.active {
                padding-bottom: 13px;
            }

            &[aria-expanded="true"],
            &:hover,
            &:active {
                color: #54e38e;
            }

            &.dropdown-toggle {
                &:after {
                    position: relative;
                    top: 2px;
                    transition: transform 0.1s linear;
                }
                &[aria-expanded="true"]:after {
                    transform: rotate(180deg);
                }
            }
        }
    }

    &__estimated {
        color: #71919c;
        text-align: right;
        justify-content: flex-end;
        margin-left: auto;

        span {
            color: #ffffff;
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
        }
    }

    .md({
        &_type_open:not(.header_type_collapse),
        &_type_open {
            transition: none;
            background-color: #23363d;
        }

        &__user_menu {
            width: 100%;
            display: flex;
            justify-content: center;
            padding: 20px 0;

            a {
                width: 120px;
            }
        }

        .navbar-collapse {
            transition: none !important;

            &:not(.show) {
                display: none !important;
            }
            &.show {
                display: flex !important;
                flex-basis: 100%;
                flex-grow: 1;
                align-items: center;
            }

            background-color: #23363d;
        }
    });

    &__user-dropdown-email {
        padding: 6px 20px 0 20px;
        max-width: 200px;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 13px;
        line-height: 16px;
        font-weight: 400;
        color: #859398;

        .lg({
            max-width: 100%;
            text-align: center;
            text-overflow: ellipsis;
            white-space: nowrap;
            font-size: 16px;
            color: #00d3a2;
            padding: 15px 15px 5px 15px;
            font-weight: 700;
        });
    }

    &__toggle {
        margin-left: 21px;
        transform: translateY(1px);

        .md({
            transform: translateY(0);
            display: none;
        })
    }

    &__toggle-switch {
        position: relative;
        display: inline-block;
        width: 176px;
        height: 30px;
        margin: auto;

        background: #1F2E33;
        box-sizing: border-box;
        border-radius: 30px;

        @{bl}__toggle-slider {
            border: 1px solid #4AD39F;
        }
    }

    &__toggle-switch-margin {

        @{bl}__toggle-slider {
            border: 1px solid #E24481;
        }
    }

    &__toggle-checkbox {
        opacity: 0;
        width: 0;
        height: 0;
    }

    &__toggle-content {
        font-weight: bold;
        font-size: 12px;
        line-height: 15px;
        text-align: center;
        color: @color-white;
        opacity: 0.5;
        padding: 5px 15px;
        transform: translateY(-.5px);

        .md({
            transform: translateY(.5px);
        })
    }

    &__toggle-tha {

        @{bl}__toggle-switch {
            width: 200px;
            height: 45px;
        }

        @{bl}__toggle-content {
            top: 1px;
            position: relative;

            .md({
                top: -1px;
            });
        }
    }

    &__toggle-msa {
        @{bl}__toggle-switch {
            width: 182px;
        }
    }

    &__toggle-content_type_exchange {
        background: linear-gradient(76.31deg, #41C7AF 5.5%, #4AD49F 97.76%);
        box-shadow: 0 4px 14px rgba(0, 0, 0, 0.3);
        border-radius: 30px;
        opacity: 1;
    }

    &__toggle-slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: #1F2E33;
        transition: .4s;
        display: flex;
        align-items: center;
        justify-content: center;

        &_type {
            &_is-margin {
                @{bl}__toggle-content_type_exchange {
                    background: none;
                    box-shadow: 0 0 0;
                    opacity: 0.5;
                }

                @{bl}__toggle-content_type_margin {
                    background: linear-gradient(79.06deg, #E14381 1.53%, #EE5484 95.02%);
                    box-shadow: 0 4px 14px rgba(0, 0, 0, 0.3);
                    border-radius: 30px;
                    opacity: 1;
                }
            }
        }
    }

    &__toggle-slider-border {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border: 4px solid transparent;
        border-left-width: 2px;
        border-right-width: 2px;
        width: 100%;
        border-radius: 30px;
    }

    &__toggle-slider-round {
        border-radius: 34px;

        &:before {
            border-radius: 50%;
        }
    }

    &__exchange {
        background: #28393f url("/i/header_exchange.jpg") no-repeat !important;

        .md({
            background: none !important;
        });
    }

    &__margin {
        background: #28393f url("/i/header_margin.jpg") no-repeat !important;

        .md({
            background: none !important;
        });
    }

    &__btns-success {
        box-shadow: 0 4px 14px rgba(17, 18, 18, 0.3);
        border-radius: 60px;
        padding-top: 11px;

        &:hover {
            color: white;
        }
    }

    &__btns-pink {
        box-shadow: 0 4px 14px rgba(0, 0, 0, 0.3);
        border-radius: 60px;
        padding-top: 11px;

        &:hover {
            color: white;
        }
    }

    &__btns {
        .btn {
            font-weight: 600;
            font-size: 14px;
            line-height: 17px;
            padding-left: 14px;
            padding-right: 15px;

            .fi {
                transform: translateY(0);
                margin-right: 7px;
                font-size: 20px;
            }

            .sm({
                margin-left: 0;
            });
        }

        .btn-outline-success {
            border: 1px solid #41C7AE;
            padding-right: 14px;
            padding-left: 15px;
            min-width: 155px;
            background: #1F2E33;

            &:hover {
                border: 1px solid #41C7AE;
            }
        }

        .btn-outline-pink {
            border: 1px solid #E24481;
            padding-right: 14px;
            padding-left: 15px;
            min-width: 155px;
            background: #1F2E33;

            &:hover {
                border: 1px solid #E24481;
            }
        }
    }


    &__button-tradein {
        .header__btns-fi {
            font-size: 10px !important;
        }
    }

    &__button-exchange {
        .header__btns-fi {
            font-size: 16px !important;
            margin-right: 5px !important;
        }
    }

    &__btns-no-hover {
        &:hover {
            background: transparent !important;
        }

        &.btn-outline-success,
        &.btn-outline-pink {
            &:hover {
                background: #1f2e33 !important;
            }
        }
    }

    &__btns-icon {
        position: absolute;
        right: 10px;
        top: 9px;
    }

    .nav-item {

        .header__btns {

            .md({
                padding-top: 20px;
                padding-left: 18px;
                padding-right: 18px;
                margin-bottom: 12px;
                display: inline-block;
            });

            .fi {
                .md({
                   font-size: 24px !important;
                });
            }

            .header__button-tradein {
                .fi {
                    .md({
                        font-size: 14px !important;
                        margin-right: 9px;
                        transform: translateY(-2px);
                    });
                }
            }

        }

        .btn {
            .md({
                padding-top: 14px;
                padding-bottom: 14px;
                height: 100%;
            });

            span {
                .md({
                    height: auto;
                    width: 100%;
                    font-size: 16px;
                    line-height: 20px;
                });
            }
        }
    }

    @{bl}__btns-is-mobile {
        margin: auto 0 auto auto;
        text-align: right;

        .btn,
        .btn:hover{
            min-width: auto;
            padding-top: 5px;
            padding-bottom: 5px;
            margin: 0 20px 0 0;

            background: #172428 !important;
            border: 1px solid #28393F;
            box-sizing: border-box;
            box-shadow: inset 0 4px 4px rgba(0, 0, 0, 0.25);
            border-radius: 30px;
        }
    }

    @{bl}__nav-item-is-mobile {
        border-bottom: 1px solid #172428;

        .nav-link {
            border-bottom: 0;
        }
    }

    .dropdown-settings-mobile-menu {

        display: none;

        .md({
            display: flex;
        });

        .dropdown-menu {
            background-color: #162327;
        }

        .dropdown-divider {
            border-color: #0d1315;
        }

        .color-light-gray {
            color: @color-light-gray !important;
        }
    }

    .dropdown-item-status-user {
        padding-left: 47px;

        .fi {
            transform: translateY(0);
        }
    }
}

.header__inner-group {
    .header-lang {
        margin-right: 0;
        margin-left: 5px;
        order: 0;
        min-width: 50px;

        &__code {
            display: inline-block;
            transition: all .2s linear;
            width: auto !important;

            &:after {
                display: inline;
                //position: static;
                margin-left: 3em;
            }
        }

        &__icon {

            display: none;
        }

        .icon {
            display: none !important;
        }

        &:hover, &:focus, &:active {
            .header-lang__code {
                color: @color-primary !important;
            }
        }
    }
}

.header__inner-group-lang {
    margin-left: auto;
    margin-right: 15px;

    .header-lang {
        &__code {
            &:after {
                display: inline-block;
                position: absolute;
                margin-left: 3em;
            }
        }
    }
}

.header-lang {
    order: 1;
    margin-left: 15px;

    .sm({
        margin-right: 11px;
    });

    .md({
        order: 0;
    });

    &.collapsing {
        transition: none;
        display: none;
    }

    &__code {
        display: none;
        font-style: normal;
        font-weight: 600;
        font-size: 14px !important;
        width: 20px !important;
        height: 14px !important;
        border: 0 !important;
        background: none !important;
        text-transform: uppercase;
        color: @color-white !important;
        margin: 0 !important;
        position: relative;

        &:after {
            display: inline-block;
            margin-left: .255em;
            vertical-align: .255em;
            content: "";
            border-top: .3em solid;
            border-right: .3em solid transparent;
            border-bottom: 0;
            border-left: .3em solid transparent;
            position: absolute;
            right: 0;
            top: 0;
            transform: translate(16px, 6px);
        }

        .sm({
            display: inline-block;
        })
    }

    &__icon {

        .sm({
            display: none;
        })
    }

    .dropdown-toggle {
        cursor: pointer;
        display: flex;
        align-items: center;
        height: 42px;
        justify-content: center;

        &:after {
            display: none;
        }

        span:nth-child(2) {
            border-radius: 2px;
            background: #3b5159;
            height: 12px;
            width: 12px;
            line-height: 12px;
            font-size: 8px;
            color: #8eb5c3;
            margin-left: 10px;
            text-align: center;
        }

        .icon {
            width: 18px;
            height: 18px;
            border-radius: 100%;
            overflow: hidden;
            background: #ffffff;

            img {
                display: block;
                width: 100%;
            }
        }
    }

    .dropdown-menu {
        background: #ffffff;
        padding: 6px 0;

        a {
            width: 100%;
            display: block;
            padding: 5px 10px;
            font-size: 13px;
            line-height: 16px;
            color: #000;
            position: relative;
            white-space: nowrap;

            span {
                display: inline-block;
                vertical-align: middle;
            }

            &:hover,
            &:active {
                background: #f3f4f8;
            }

            &.active {
                cursor: default;
                background: #ecedf3;
            }
        }

        .icon {
            width: 18px;
            height: 18px;
            border-radius: 100%;
            overflow: hidden;
            background: @color-white;
            margin-right: 8px;

            .sm({
                display: none;
            });

            img {
                display: block;
                width: 100%;
            }
        }
    }
}

#subheader-unverify,
#subheader-verify {
    vertical-align: middle;
    color: #20b335;
    font-size: 14px;
    line-height: 20px;
    padding: 10px 15px;
    text-align: center;

    i {
        margin-left: 0;
        transform: none;
        display: inline-block;
        vertical-align: middle;
    }

    span {
        display: inline-block;
        vertical-align: middle;
    }

    .lg-mf({
        font-size: 11px;
        line-height: 16px;
        text-align: left;
        padding: 2px 20px 6px 20px;
    });
}
#subheader-verify {
    color: #20b335;
}
#subheader-unverify {
    color: #fd3825;
}

.header.header_type_user {
    background-color: #28393f;

    .md({
        background-color: #23363d;
    });
}

.page_type_public {
    .header__submenu-wrapper {
        z-index: 200;
    }

    .header {
        z-index: 200;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        transition: all 0.3s linear;
        transform: translateY(0);

        &:not(.header_type_collapse) {
            background: none;

            &.header_type_user {
                background-color: #28393f;

                .md({
                    background: none;
                });
            }

            &.header_type_open {
                background-color: #23363d;
            }
        }

        #header-b-vue-root {
            transition: all 0.1s linear;
        }
        &.header_type_collapse #header-b-vue-root {
            margin-top: -100px;
        }

        .md({
            max-height: 100vh;
            align-items: baseline;

            &.header_type_open {
                overflow: auto;
            }
        })
    }

    .navbar_stick_floating {
        transform: translateY(-100%);
    }
}





#header-b-vue-root {
    width: 100%;
    height: 60px;
    transform: translate(0,-100%);
    animation-duration: 0.3s;
    animation-timing-function: ease-in-out;
    animation-fill-mode: forwards;
    animation-name: header-b;

    .swiper-slide a:focus,
    .swiper-slide:focus {
        outline: none;
    }

    .xs({
        height: 100px;
    });
}
.header-b {
    &__bithoven {
        overflow: hidden;
        display: block;
        width: 100%;
        height: 60px;
        background: #5D6EF4;
        background-size: cover;
        padding: 0;

        .xs({
            height: 100px;
        });

        .container {
            height: 100%;
            position: relative;
        }
    }

    &__bithoven-dog_1,
    &__bithoven-dog_2 {
        position: absolute;
        background: url(/i/header-b/bithoven/dog.png?v=2) no-repeat center;
        background-size: contain;
        z-index: 10;
    }

    &__bithoven-dog_1 {
        width: 50px;
        height: 50px;
        left: 0;
        top: 50%;
        transform: translate(0,-200%);
        animation-duration: 8s;
        animation-timing-function: ease-in-out;
        animation-fill-mode: forwards;

        .xs({
            left: 10px;
        });

        .swiper-slide-active & {
            animation-name: banner-bithoven-dog-1;
        }
    }

    &__bithoven-dog_2 {
        width: 80px;
        height: 80px;
        right: 0;
        top: 20px;
        transform: translate(0,100%);
        animation-duration: 8s;
        animation-timing-function: ease-in-out;
        animation-fill-mode: forwards;

        .xs({
            display: none;
        });

        .swiper-slide-active & {
            animation-name: banner-bithoven-dog-2;
        }
    }

    &__bithoven-block_2,
    &__bithoven-block_1 {
        opacity: 0;
        font-size: 16px;
        line-height: 22px;
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        font-weight: 700;
        color: #ffffff;
        position: absolute;
        padding: 0 220px 0 70px;
        transform: translate(0,100%);

        span {
            color: #62f6ff;
        }

        .xs({
            justify-content: flex-start;
            font-size: 13px;
            line-height: 18px;
            padding: 0 10px 0 70px;
        });
    }

    &__bithoven-block_1 {
        animation-duration: 8s;
        animation-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
        animation-fill-mode: forwards;

        .swiper-slide-active & {
            animation-name: banner-bithoven-block;
        }
    }

    &__bithoven-block_2 {
        transform: translate(0,100%);
        animation-duration: 8s;
        animation-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
        animation-delay: 2.5s;
        animation-fill-mode: forwards;

        .swiper-slide-active & {
            animation-name: banner-bithoven-block;
        }
    }

    &__bithoven-block_3 {
        opacity: 0;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        animation-duration: 8s;
        animation-timing-function: linear;
        animation-fill-mode: forwards;

        .swiper-slide-active & {
            animation-name: banner-bithoven-block_3;
        }
    }

    &__bithoven-subblock_1 {
        opacity: 0;
        display: block;
        width: 127px;
        min-width: 127px;
        height: 22px;
        margin-right: 10px;
        background: url(/i/header-b/bithoven/logo.png?v=2) no-repeat center;
        animation-duration: 8s;
        animation-timing-function: linear;
        animation-fill-mode: forwards;

        .xs({
            display: none;
        });
    }

    &__bithoven-subblock_2 {
        opacity: 0;
        display: block;
        background: url(/i/header-b/bithoven/dog.png?v=2) no-repeat center;
        background-size: contain;
        width: 50px;
        height: 50px;
        animation-duration: 8s;
        animation-timing-function: linear;
        animation-delay: 0.3s;
        animation-fill-mode: forwards;

        .xs({
            width: 80px;
            height: 80px;
        });
    }

    &__bithoven-subblock_3 {
        opacity: 0;
        display: block;
        width: 126px;
        min-width: 126px;
        height: 40px;
        margin-left: 10px;
        background: url(/i/header-b/bithoven/btn.png?v=2) no-repeat center;
        animation-duration: 8s;
        animation-timing-function: linear;
        animation-delay: 0.6s;
        animation-fill-mode: forwards;
        color: #ffffff;
        font-size: 14px;
        line-height: 40px;
        font-weight: 600;
        padding: 0 35px 0 0;
        text-decoration: none;
        text-align: right;

        .xs({
            width: 160px;
            height: 70px;
            line-height: 70px;
            padding: 0 55px 0 0;
        });
    }

    .swiper-slide-active &__bithoven-subblock_3,
    .swiper-slide-active &__bithoven-subblock_2,
    .swiper-slide-active &__bithoven-subblock_1 {
        animation-name: banner-bithoven-subblock;
    }

    &__bithoven-btn {
        position: absolute;
        right: 80px;
        top: 50%;
        width: 126px;
        min-width: 126px;
        height: 40px;
        z-index: 10;
        transform: translate(0,-200%);
        background: url(/i/header-b/bithoven/btn.png?v=2) no-repeat center;
        animation-duration: 8s;
        animation-timing-function: ease-in-out;
        animation-fill-mode: forwards;
        color: #ffffff;
        font-size: 14px;
        line-height: 40px;
        font-weight: 600;
        padding: 0 35px 0 0;
        text-decoration: none;
        text-align: right;

        .xs({
            display: none;
        });

        .swiper-slide-active & {
            animation-name: banner-bithoven-dog-1;
        }
    }


    &__lendabit {
        overflow: hidden;
        display: block;
        width: 100%;
        height: 60px;
        background: #5963ba url(/i/header-b/lendabit/bg.png?v=2) repeat-y center;
        padding: 0;

        .xs({
            height: 100px;
        });

        .container {
            height: 100%;
            position: relative;

            .sm({
                width: auto;
            });
        }

        &-arrows {
            width: 100px;
            height: 100%;
            position: absolute;
            top: 0;
            left: 50%;
            opacity: 0;
            transition: translate(0,100%);
            margin-left: -50px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            animation-delay: 0.5s;
            animation-duration: 8s;
            animation-timing-function: ease-in-out;
            animation-fill-mode: forwards;

            .xs({
                width: 120px;
                margin-left: -60px;
                top: 50px;
                opacity: 1;
                transition: translate(0,0);
            });

            div {
                opacity: 0.4;
                width: 18px;
                height: 34px;
                background: url(/i/header-b/lendabit/arrow.png?v=2) no-repeat center;
                position: relative;
                animation-duration: 1s;
                transition: translate(0,50%);
                animation-timing-function: ease-in-out;
                animation-fill-mode: forwards;
                animation-iteration-count: infinite;
                animation-name: banner-lendabit-arrow;
            }
        }
        .swiper-slide-active &-arrows {
            .sm-mf({
                animation-name: banner-lendabit-arrows;
            });
        }

        &-coin {
            width: 53px;
            height: 100%;
            display: block;
            position: absolute;
            top: 50%;
            left: 50%;
            background: url(/i/header-b/lendabit/coin.png?v=2) no-repeat center;
            background-size: contain;
            transform: translate(-50%,100%);
            opacity: 1;
            z-index: 10;
            animation-delay: 0.5s;
            animation-duration: 8s;
            animation-timing-function: ease-in-out;
            animation-fill-mode: forwards;

            .xs({
                top: 100px;
            });
        }
        .swiper-slide-active &-coin {
            animation-name: banner-lendabit-coin;
        }

        &-bitcoin {
            width: 56px;
            height: 100%;
            display: block;
            position: absolute;
            top: 50%;
            left: 50%;
            background: url(/i/header-b/lendabit/bitcoin.png?v=2) no-repeat center;
            background-size: contain;
            transform: translate(-50%,100%);
            opacity: 1;
            z-index: 20;
            animation-delay: 0.5s;
            animation-duration: 8s;
            animation-timing-function: ease-in-out;
            animation-fill-mode: forwards;

            .xs({
                top: 100px;
            });
        }
        .swiper-slide-active &-bitcoin {
            animation-name: banner-lendabit-bitcoin;
        }

        &-text_1,
        &-text_2 {
            font-size: 18px;
            line-height: 24px;
            color: #ffffff;
            font-weight: 700;
            position: absolute;
            left: 80px;
            top: 0;
            display: flex;
            height: 100%;
            width: ~"calc(50% - 80px)";
            padding-left: 15px;
            align-items: center;
            justify-content: flex-end;
            opacity: 0;
            animation-delay: 0.5s;
            animation-duration: 8s;
            animation-timing-function: ease-in-out;
            animation-fill-mode: forwards;

            .xs({
                left: 0;
                padding-left: 0;
                align-items: flex-start;
                justify-content: flex-start;
                font-size: 16px;
                line-height: 24px;
                width: 100%;
                left: 80px;
                padding: 40px 140px 0 10px;
            });
        }
        .swiper-slide-active &-text_1 {
            animation-name: banner-lendabit-left_1;
        }
        .swiper-slide-active &-text_2 {
            animation-name: banner-lendabit-left_2;
        }

        &-logo {
            position: absolute;
            left: 50%;
            top: 50%;
            height: 22px;
            width: 142px;
            background: url(/i/header-b/lendabit/logo.png?v=2) no-repeat center;
            background-size: contain;
            transform: translate(0,-50%);
            opacity: 0;
            animation-delay: 0.5s;
            animation-duration: 8s;
            animation-timing-function: ease-in-out;
            animation-fill-mode: forwards;

            .xs({
                opacity: 1;
                top: 10px;
                left: 10px;
                transform: translate(0,0);
            });
        }
        .swiper-slide-active &-logo {
            .sm-mf({
                animation-name: banner-lendabit-right_1;
            });
        }

        &-btn {
            position: absolute;
            left: 50%;
            top: 50%;
            height: 50px;
            width: 126px;
            background: url(/i/header-b/lendabit/btn.png?v=2) no-repeat center;
            background-size: contain;
            transform: translate(0,-50%);
            opacity: 0;
            animation-delay: 0.5s;
            animation-duration: 8s;
            animation-timing-function: ease-in-out;
            animation-fill-mode: forwards;
            color: #ffffff;
            font-size: 14px;
            line-height: 48px;
            font-weight: 600;
            text-align: center;

            .xs({
                opacity: 1;
                right: 10px;
                left: auto;
            });
        }
        .swiper-slide-active &-btn {
            .sm-mf({
                animation-name: banner-lendabit-right_2;
            });
        }
    }
}

@keyframes header-b {
    0% {
        transform: translate(0,-100%);
    }
    100% {
        transform: translate(0,0);
    }
}
@keyframes banner-bithoven-block {
    0% {
        opacity: 0;
        transform: translate(0,100%);
    }
    5% {
        opacity: 1;
        transform: translate(0,0);
    }
    30% {
        opacity: 1;
        transform: translate(0,0);
    }
    35% {
        opacity: 0;
        transform: translate(0,-100%);
    }
    100% {
        opacity: 0;
        transform: translate(0,-100%);
    }
}
@keyframes banner-bithoven-block_3 {
    0% {
        opacity: 0;
    }
    65% {
        opacity: 0;
    }
    70% {
        opacity: 1;
    }
    100% {
        opacity: 1;
    }
}
@keyframes banner-bithoven-subblock {
    0% {
        opacity: 0;
    }
    65% {
        opacity: 0;
    }
    68% {
        opacity: 1;
    }
    100% {
        opacity: 1;
    }
}
@keyframes banner-bithoven-dog-1 {
    0% {
        transform: translate(0,-200%);
    }
    5% {
        transform: translate(0,-50%);
    }
    65% {
        transform: translate(0,-50%);
    }
    70% {
        transform: translate(0,-200%);
    }
    100% {
        transform: translate(0,-200%);
    }
}
@keyframes banner-bithoven-dog-2 {
    0% {
        transform: translate(0,100%);
    }
    5% {
        transform: translate(0,0);
    }
    65% {
        transform: translate(0,0);
    }
    70% {
        transform: translate(0,100%);
    }
    100% {
        transform: translate(0,100%);
    }
}

@keyframes banner-lendabit-coin {
    0% {
        transform: translate(-50%,100%);
    }
    5% {
        transform: translate(-50%,-50%);
    }
    100% {
        transform: translate(-50%,-50%);
    }
}
@keyframes banner-lendabit-bitcoin {
    0% {
        transform: translate(-50%,-200%);
    }
    40% {
        transform: translate(-50%,-200%);
    }
    50% {
        transform: translate(-50%,-50%);
    }
    100% {
        transform: translate(-50%,-50%);
    }
}
@keyframes banner-lendabit-left_1 {
    0% {
        opacity: 0;
        transform: translate(0,0);
    }
    10% {
        opacity: 1;
        transform: translate(-80px,0);
    }
    40% {
        opacity: 1;
        transform: translate(-80px,0);
    }
    50% {
        opacity: 0;
        transform: translate(0,0);
    }
    100% {
        opacity: 0;
        transform: translate(0,0);
    }
}
@keyframes banner-lendabit-left_2 {
    0% {
        opacity: 0;
        transform: translate(0,0);
    }
    45% {
        opacity: 0;
        transform: translate(0,0);
    }
    55% {
        opacity: 1;
        transform: translate(-80px,0);
    }
    100% {
        opacity: 1;
        transform: translate(-80px,0);
    }
}
@keyframes banner-lendabit-right_1 {
    0% {
        opacity: 0;
        transform: translate(0,-50%);
    }
    10% {
        opacity: 1;
        transform: translate(80px,-50%);
    }
    40% {
        opacity: 1;
        transform: translate(80px,-50%);
    }
    50% {
        opacity: 0;
        transform: translate(0,-50%);
    }
    100% {
        opacity: 0;
        transform: translate(0,-50%);
    }
}
@keyframes banner-lendabit-right_2 {
    0% {
        opacity: 0;
        transform: translate(0,-50%);
    }
    45% {
        opacity: 0;
        transform: translate(0,-50%);
    }
    55% {
        opacity: 1;
        transform: translate(80px,-50%);
    }
    100% {
        opacity: 1;
        transform: translate(80px,-50%);
    }
}
@keyframes banner-lendabit-arrows {
    0% {
        opacity: 0;
        transform: translate(0,100%);
    }
    5% {
        opacity: 0;
        transform: translate(0,100%);
    }
    10% {
        opacity: 1;
        transform: translate(0,0);
    }
    40% {
        opacity: 1;
        transform: translate(0,0);
    }
    50% {
        opacity: 0;
        transform: translate(0,-100%);
    }
    100% {
        opacity: 0;
        transform: translate(0,-100%);
    }
}
@keyframes banner-lendabit-arrow {
    0% {
        opacity: 0.4;
    }
    50% {
        opacity: 0;
    }
    100% {
        opacity: 0.4;
    }
}

/* /Header */
