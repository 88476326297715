@import (reference) "_helpers.less";

/* Footer */
.footer {
    @bl: ~'.footer';

    .ft-logo img {
        max-width: 136px;
    }

    &_type_inner {
        display: flex;
        padding: 2.4rem 0 .6rem;
        background: #202d32;

        .xs({
            text-align: left;
            padding-left: 15px;
            padding-right: 15px;
        });

        @{bl}__title {
            font-size: 18px;
            color: @color-white;
            margin-bottom: 1.5rem;

            .xs({
                margin: 1.2rem 0 .5rem 0;
                font-size: 16px;
                line-height: 20px;
                font-weight: 500;
                position: relative;
                cursor: pointer;

                &:after {
                    position: absolute;
                    top: 0;
                    right: 0;
                    display: inline-block;
                    width: 0;
                    height: 0;
                    margin-left: .255em;
                    vertical-align: .255em;
                    content: "";
                    border-top: .3em solid;
                    border-right: .3em solid transparent;
                    border-bottom: 0;
                    border-left: .3em solid transparent;
                }
            });
        }

        .xs({

            @{bl}__list {
                display: none;
            }

            .js-footer-inner-active {
                @{bl}__list {
                    display: block;
                }
            }

            .js-footer-block-active {
                @{bl}__title {

                    &:after {
                        border-top: 0;
                        border-bottom: .3em solid;
                    }
                }
            }
        });

        @{bl}__list-item {
            // display: inline-block;
            margin: 0.9rem 0 0 0;

            .xs({
                margin: 1rem 0 0 0;
            });
        }

        @{bl}__link {
            font: 500 12px/16px Montserrat, "Source Sans Pro", sans-serif;
            color: @color-light-blue;

            &:focus {
                color: @color-white;
            }
            &:hover,
            &:active {
                color: @color-hover;
            }

            .fi {
                vertical-align: super;
                width: 17px;
            }

            .xs({
                font-size: 13px;
            });
        }

        @{bl}__link-text {
            transform: translateY(-10%);
        }

        @{bl}__social {
            .btn {
                color: @color-white;
                font-size: 13px;
                min-width: 160px;
                width: 100%;
                text-align: left;
                padding: 6px 12px;

                .xs({
                    width: auto;
                });
            }

            .fi {
                font-size: 16px;
                margin-right: .8em;
            }
        }

        @{bl}__social-item {
            margin: 1rem 0 0 0;
        }

        @{bl}__block-divider {
            padding-right: 40px;
            position: relative;

            .md({
                padding-right: 0;
            });
        }

        @{bl}__copy {
            color: @color-pale-sky;
            font-size: 12px;
            line-height: 20px;
            margin-top: 35px;
            margin-bottom: 14px;
            padding-top: 20px;
            font-weight: 500;
            border-top: 1px solid @color-default;

            .xs({
                border: 0;
                color: #9CB1B9;
                padding-top: 0;
            });
        }

        @{bl}__block {
            &_type {
                &_last {
                    .xs({
                        display: flex;
                        flex-wrap: wrap;
                        margin-top: 10px;
                        width: 100%;
                    });

                    @{bl}__list {
                        .xs({
                            display: flex;
                            justify-content: space-around;
                            width: 100%;
                        });
                    }

                    @{bl}__social-item {
                        .xs({
                            order: 1;

                            + @{bl}__social-item {
                                order: 0;
                            }
                        });
                    }

                    @{bl}__title {
                        .xs({
                           display: none;
                        });
                    }

                    @{bl}__link {
                        .xs({
                            background: none;
                        });
                    }

                    @{bl}__link-text {
                        .xs({
                            font-size: 16px;
                            font-weight: bold;
                        });
                    }
                }

                &_social {
                    .xs({
                        order: 1;
                        margin-top: 10px;
                        width: 100%;
                        display: flex;

                        .btn {
                            padding: 0;
                            min-width: auto;
                            display: inline-block;
                        }

                        .fi {
                            margin-right: 6px;
                        }
                    });
                }

                &_bitgo {
                    .xs({
                        order: 0;
                        width: 100%;

                        @{bl}__secure {
                            justify-content: space-around;
                        }

                        @{bl}__secure-img {
                            order: 1;

                            + @{bl}__secure-img {
                                order: 0;
                                margin-left: 0;
                            }
                        }
                    });
                }
            }
        }
    }

    &_type_public {
        background: #172428;

        .container {
            padding-top: 50px;
            padding-bottom: 50px;
            background: url('/i/footer-bg.png') ~"calc(100% - 20%)" center no-repeat;
        }

        @{bl}__title {
            font-weight: 700;
            font-size: 20px;
            line-height: 24px;
            color: #ffffff;
            width: 100%;
            margin-bottom: 30px;
        }

        @{bl}__block {
            margin: 20px 0;

            ul {
                margin: 0;
            }
            li {
                margin: 10px 0;
            }
            a {
                color: #94a4b0;
                font-weight: 400;
                font-size: 14px;
                line-height: 24px;

                &:hover,
                &:active {
                    color: #ffffff;
                }
            }
        }

        @{bl}__copy {
            color: #94a4b0;
            font-size: 12px;
            line-height: 20px;
            margin-top: 40px;
            font-weight: 400;
            border: none;
        }

        @{bl}__subscribe_form {
            .form-control {
                &:focus {
                    border-color: #00ff9c;
                }
            }
        }
    }

    &__secure {
        margin-top: -10px;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: center;

        .sm({
            justify-content: center;
            margin-top: 0;
        });
    }

    &__secure-img {
        + .footer__secure-img {
            margin-left: 6px;
        }
    }
}
/* /Footer */
