// Colors

@color-white: #fff;
@color-gray: #9c9c9c;
@color-light-gray: #728c94;
@color-light-blue: #9cb1b9;
@color-alice-blue: #eef2f5;
@color-snow: #f9f9f9;
@color-pale-sky: #637176;
@color-default: #33454b;
@color-blue-dark: #38525c;
@color-black: #000;
@color-error: #fd3825;
@color-sunset-orange: #f55555;
@color-pink: #e23f83;
@color-turbo: #fccf31;
@color-primary: #41c7af;
@color-border: #45be8c;
@color-emerald: #3fc87a ;
@color-green: #4bbb8c;
@color-success: #20b335;
@color-hover: #00ff9c;
@color-navy-blue: #166ae6;
@color-navy-blue-dark: #195abb;
@color-sky-blue: #00aaec;
@color-sky-blue-dark: #058bbf;
@color-perano: #B4B4FF;

@color-link: @color-primary;

@color-bg-default: #f3f4f8;

.color {
    &-success {
        color: @color-success;
    }

    &-default {
        color: @color-default;
    }

    &-light-blue {
        color: @color-light-blue;
    }

    &-light-gray {
        color: @color-light-gray;
    }

    &-gray {
        color: @color-gray;
    }

    &-link {
        color: @color-link;
    }

    &-white {
        color: @color-white;
    }

    &-error {
        color: @color-error;
    }

    &-pink {
        color: @color-pink;
    }

    &-primary {
        color: @color-primary;
    }

    &-border {
        color: @color-border;
    }

    &-black {
        color: @color-black;
    }
}

.bg {
    &-alice-blue {
        background-color: @color-alice-blue;
    }

    &-color-primary {
        background-color: @color-primary;
    }

    &-color-white {
        background-color: @color-white;
    }
}

.link {
    &-decoration {
        text-decoration: underline;

        &:hover {
            text-decoration: underline;
        }
    }

    &-decoration-none {
        text-decoration: none;

        &:hover,
        &:focus {
            text-decoration: none;
        }
    }
}


.va-middle {
    vertical-align: middle !important;
}

@media (min-width: 768px) {
    .text-right-xs,
    .text-center-xs {
        text-align: left;
    }
}

.fw {
    &-bold {
        font-weight: 800;
    }

    &-semibold {
        font-weight: 600;
    }

    &-normal {
        font-weight: normal;
    }
}


.fs {
    &-0 {
        font-size: 0.7143rem;  // 10px, .small -> 11.2px
    }

    &-1 {
        font-size: 1rem;       // 14px
    }

    &-2 {
        font-size: 1.2rem;     // 16.8px
    }

    &-3 {
        font-size: 1.4286rem;  // 20px
    }

    &-4 {
        font-size: 1.7143rem;  // 24px
    }

    &-5 {
        font-size: 2.2857rem;  // 32px
    }
}

.white-space {
    &_normal {
        white-space: normal;
    }

    &_nowrap {
        white-space: nowrap;
    }
}

.word-break {
    &_break-word {
        word-break: break-word;
    }

    &_break-all {
        word-break: break-all;
    }
}

.cursor-pointer {
    cursor: pointer;
}

.pos {
    &-relative {
        position: relative;
    }

    &-absolute {
        position: absolute;
    }
}

.display-none {
    display: none;
}

.center-block {
    margin-left: auto;
    margin-right: auto;
}

.transparent {
    color: rgba(255,255,255,255);

    &:hover,
    &:focus,
    &:active {
        color: rgba(255,255,255,255);
    }
}

.border {
    &-none {
        border: 0;
    }
}


/*
* BADGES
*/
span.badge-pulse {
    background: #fe195e;
    display: block;
    width: 14px;
    height: 14px;
    border-radius: 50%;
    box-shadow: 0 0 0 rgba(254, 25, 94, 0.4);
    animation: BadgePulse 1.8s infinite;
}

@keyframes BadgePulse {
    0% {
        box-shadow: 0 0 0 0 rgba(254, 25, 94, 0.4);
    }
    70% {
        box-shadow: 0 0 0 10px rgba(254, 25, 94, 0);
    }
    100% {
        box-shadow: 0 0 0 20px rgba(254, 25, 94, 0);
    }
}

span.badge-pulse-green {
    background: #60c400;
    display: block;
    width: 14px;
    height: 14px;
    border-radius: 50%;
    box-shadow: 0 0 0 rgba(105, 205, 0, 0.4);
    animation: BadgePulseGreen 1.8s infinite;
}


@keyframes BadgePulseGreen {
    0% {
        box-shadow: 0 0 0 0 rgba(105, 205, 0, 0.4);
    }
    70% {
        box-shadow: 0 0 0 10px rgba(105, 205, 0, 0);
    }
    100% {
        box-shadow: 0 0 0 20px rgba(105, 205, 0, 0);
    }
}

span.badge-pulse-blue {
    background: #08a6c3;
    display: block;
    width: 14px;
    height: 14px;
    border-radius: 50%;
    box-shadow: 0 0 0 rgba(8, 166, 195, 0.4);
    animation: BadgePulseBlue 1.8s infinite;
}

@keyframes BadgePulseBlue {
    0% {
        box-shadow: 0 0 0 0 rgba(8, 166, 195, 0.4);
    }
    70% {
        box-shadow: 0 0 0 10px rgba(8, 166, 195, 0);
    }
    100% {
        box-shadow: 0 0 0 20px rgba(8, 166, 195, 0);
    }
}

span.badge-pulse-orange {
    background: #f7b733;
    display: block;
    width: 14px;
    height: 14px;
    border-radius: 50%;
    box-shadow: 0 0 0 rgba(247, 183, 51, 0.4);
    animation: BadgePulseOrange 1.8s infinite;
}

@keyframes BadgePulseOrange {
    0% {
        box-shadow: 0 0 0 0 rgba(247, 183, 51, 0.4);
    }
    70% {
        box-shadow: 0 0 0 10px rgba(247, 183, 51, 0);
    }
    100% {
        box-shadow: 0 0 0 20px rgba(247, 183, 51, 0);
    }
}

.badge {
    font-weight: 500;
}

.badge-text {
    background: #e4e8f0;
    color: #fff;
    padding: 0.4rem 0.7rem;
    border-radius: 50px;
    font-size: 0.9rem;
    text-align: center;
    display: inline-block;
    min-width: 20px;
    min-height: 20px;
    vertical-align: middle;
}

.badge-text.badge-text-small {
    padding: 0.1rem 0.9rem;
}

.badge-text.badge-text-wide {
    padding: 0.3rem 1.4rem;
}

.badge-text.success {
    background:#60c400;
}

.badge-text.info {
    background:#08a6c3;
}

.badge-text.danger {
    background:#fe195e;
}

.badge-rounded {
    background: #5d5386;
    color: #fff;
    border-radius: 50%;
    text-align: center;
    display: inline-block;
    width: 10px;
    height: 10px;
    vertical-align: middle;
}

.badge-rounded.badge-rounded-small {
    width: 25px;
    height: 25px;
    line-height: 25px;
}

.badge-rounded.success {
    background:#60c400;
}

.badge-rounded.info {
    background:#08a6c3;
}

.badge-rounded.danger {
    background:#fe195e;
}

// LINKS
a, .is-link {
    color: @color-link;
    transition: all 0.2s linear;

    &:focus {
        color: @color-link;
    }
    &:hover {
        color: @color-hover;
    }
}


// HEADERS
h1 {
    font-size: 44px;
    line-height: 50px;
    color: #333b3f;
    // font-family: Montserrat, "Source Sans Pro", sans-serif;

    .md({
        font-size: 38px;
        line-height: 44px;
    });
}
h2 {
    font-size: 32px;
    line-height: 38px;
    color: #333b3f;
    font-weight: 500;
    // font-family: Montserrat, "Source Sans Pro", sans-serif;

    .md({
        font-size: 28px;
        line-height: 34px;
    });
}

.dropdown {
    @bl: ~'.dropdown';

    &-item {
        width: 100%;
    }
    &-item.active,
    &-item:active {
        background-color: @color-link;
        color: #ffffff;
    }

    .widget-options & {
        @{bl}-menu {
            padding: 10px 0;
        }
        @{bl}-item {
            padding: 6px 15px;
            width: 100%;
        }
        @{bl}-item.active,
        @{bl}-item:active {
            background-color: @color-link;
            color: #ffffff;
        }
    }
}

.page-header-styles() {
    // .page {
    //     .page-caption {
    //         padding-top: 128px;
    //         padding-bottom: 108px;
    //         text-align: center;

    //         .md({
    //             padding-top: 164px;
    //             padding-bottom: 90px;
    //         });

    //         .sm({
    //             padding-top: 118px;
    //             padding-bottom: 50px;
    //         });

    //         .xs({
    //             padding-top: 36px;
    //             padding-bottom: 28px;
    //         });

    //         .page-header {
    //             &__header-text {
    //                 font-size: 18px;
    //             }
    //         }
    //     }
    // }
}


.placeholder(@rules) {
    &::placeholder {
        @rules();
    }
}
