.form-group .dropdown-input-selector {
    width: 100%;
}

.form-group .dropdown-input-selector.is-invalid ~ .invalid-feedback {
    display: block;
}

.dropdown-input-selector {
    position: relative;
    display: inline-block;
    vertical-align: middle;

    .xs({
        width: 100%;
    });

    .input-dropdown-botton {
        position: relative;
        font-size: 1rem;
        border-color: @color-primary;
        border-style: solid;
        background-color: @color-primary;
        border-width: 2px !important;
        border-radius: 5px;
        cursor: pointer;
        z-index: 5;
        color: @color-white;
        padding: 5px 23px 5px 15px;
        width: 100%;
        text-align: left;

        &::after {
            position: absolute;
            right: 7px;
            bottom: 0;
            top: 0;
            margin-top: auto;
            margin-bottom: auto;
        }

        .fi {
            margin-right: 5px;
            line-height: 1.35rem;
        }

        &.is-invalid {
            border-color: @color-error;
        }

        &:disabled {
            background-color: @color-primary;
            opacity: 0.6;
            cursor: default;
        }

        &:focus {
            color: @color-light-gray;
            background-color: @color-primary;
            border-color: @color-primary;
            outline: 0;
            box-shadow: 0 0 0 0.2rem rgba(128, 191, 187, 0.25);
        }
    }

    .dropdown-menu {
        min-width: 76px;
        max-height: 290px;
        overflow: auto;
        padding: 8px 12px;

        .dropdown-item {
            cursor: pointer;
        }
    }

    .date-picker {
        position: absolute!important;;
        top: 36px;

        .input-wrapper {
            display: none !important;
        }
    }
}
