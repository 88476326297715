.alert {
    padding: 1rem 1.25rem;
    color: #fff;
    border: none;
    font-size: 0.975rem;
    line-height: 1.7rem;
    opacity: 0.88;

    &:last-child {
        margin-bottom: 0;
    }

    a, i, span {
        display: inline;
    }

    &.alert-success a {
        color: #fff;
        text-decoration: underline;

        &:hover {
            color: #f1f1f1;
        }
    }

    &__wrapper {
        width: 100%;
    }

    &-success {
        background: #83cd74;
    }

    &-danger {
        background: #dd4b39;
    }

    &-warning {
        color: #777056;
        background-color: #fdf9ea;
    }

    .close {
        top: 12px !important;
        text-shadow: none;
        font-size: 1.75rem;
        line-height: 1.4rem;
        padding-right: 0;
        transform: none;

        &::before {
            content: '';
        }
    }
}


.animated {
    animation-duration: 0.5s;
    animation-fill-mode: both;
}

@keyframes fadeInDown {
    from {
        opacity: 0;
        transform: translate3d(0, -100%, 0);
    }

    to {
        opacity: 1;
        transform: translate3d(0, 0, 0);
    }
}

.fadeInDown {
    animation-name: fadeInDown;
}

@keyframes fadeOutUp {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
        transform: translate3d(0, -100%, 0);
    }
}

.fadeOutUp {
    animation-name: fadeOutUp;
}
