@font-face {
	font-family: "font-icons";
	font-display: block;
	src: url("/dist/font-icons.0f86714d311b8e7cda6f.eot?#iefix") format("embedded-opentype"),
url("/dist/font-icons.0f86714d311b8e7cda6f.woff") format("woff"),
url("/dist/font-icons.0f86714d311b8e7cda6f.woff2") format("woff2"),
url("/dist/font-icons.0f86714d311b8e7cda6f.ttf") format("truetype"),
url("/dist/font-icons.0f86714d311b8e7cda6f.svg#font-icons") format("svg");
}

.fi {
	line-height: 1;
}

.fi:before {
	font-family: font-icons !important;
	font-style: normal;
	font-weight: normal !important;
	vertical-align: top;
}

.fi-03:before {
	content: "\f101";
}
.fi-2step:before {
	content: "\f102";
}
.fi-account-and-security:before {
	content: "\f103";
}
.fi-alert-circle:before {
	content: "\f104";
}
.fi-alert-margin:before {
	content: "\f105";
}
.fi-android:before {
	content: "\f106";
}
.fi-angle-down:before {
	content: "\f107";
}
.fi-apple:before {
	content: "\f108";
}
.fi-arrow:before {
	content: "\f109";
}
.fi-bars:before {
	content: "\f10a";
}
.fi-bell:before {
	content: "\f10b";
}
.fi-billing:before {
	content: "\f10c";
}
.fi-btc-trading:before {
	content: "\f10d";
}
.fi-bullhorn:before {
	content: "\f10e";
}
.fi-case-deposit:before {
	content: "\f10f";
}
.fi-case-withdraw:before {
	content: "\f110";
}
.fi-change-password:before {
	content: "\f111";
}
.fi-check-circle:before {
	content: "\f112";
}
.fi-check:before {
	content: "\f113";
}
.fi-chevron-down:before {
	content: "\f114";
}
.fi-circle-checked:before {
	content: "\f115";
}
.fi-circle-close:before {
	content: "\f116";
}
.fi-circle-deposit:before {
	content: "\f117";
}
.fi-circle-withdraw:before {
	content: "\f118";
}
.fi-clear:before {
	content: "\f119";
}
.fi-copy:before {
	content: "\f11a";
}
.fi-coupons:before {
	content: "\f11b";
}
.fi-deposit-withdrawal:before {
	content: "\f11c";
}
.fi-deposit:before {
	content: "\f11d";
}
.fi-deposits:before {
	content: "\f11e";
}
.fi-desktop:before {
	content: "\f11f";
}
.fi-doge-icon:before {
	content: "\f120";
}
.fi-exchange:before {
	content: "\f121";
}
.fi-experts:before {
	content: "\f122";
}
.fi-facebook-circular-logo:before {
	content: "\f123";
}
.fi-facebook:before {
	content: "\f124";
}
.fi-forex-trading:before {
	content: "\f125";
}
.fi-gear:before {
	content: "\f126";
}
.fi-general:before {
	content: "\f127";
}
.fi-google:before {
	content: "\f128";
}
.fi-goto-pull:before {
	content: "\f129";
}
.fi-guaranteed-security:before {
	content: "\f12a";
}
.fi-head-crypto-currencies:before {
	content: "\f12b";
}
.fi-head-customers-served:before {
	content: "\f12c";
}
.fi-head-exchange:before {
	content: "\f12d";
}
.fi-ib-program:before {
	content: "\f12e";
}
.fi-identification-address:before {
	content: "\f12f";
}
.fi-identification-driver:before {
	content: "\f130";
}
.fi-identification-email-stars:before {
	content: "\f131";
}
.fi-identification-email:before {
	content: "\f132";
}
.fi-identification-id-card:before {
	content: "\f133";
}
.fi-identification-identity:before {
	content: "\f134";
}
.fi-identification-passport-internal:before {
	content: "\f135";
}
.fi-identification-passport-international:before {
	content: "\f136";
}
.fi-identification-phone-stars:before {
	content: "\f137";
}
.fi-identification-phone:before {
	content: "\f138";
}
.fi-identification-selfie:before {
	content: "\f139";
}
.fi-inactivity:before {
	content: "\f13a";
}
.fi-info-circle:before {
	content: "\f13b";
}
.fi-instant-trading:before {
	content: "\f13c";
}
.fi-legal-issues--kyc:before {
	content: "\f13d";
}
.fi-leverage-up:before {
	content: "\f13e";
}
.fi-linkedin:before {
	content: "\f13f";
}
.fi-long-arrow-left:before {
	content: "\f140";
}
.fi-margin_lp-1:before {
	content: "\f141";
}
.fi-margin_lp-2:before {
	content: "\f142";
}
.fi-margin_lp-3:before {
	content: "\f143";
}
.fi-margin_lp-4:before {
	content: "\f144";
}
.fi-margin_lp-5:before {
	content: "\f145";
}
.fi-margin_lp-6:before {
	content: "\f146";
}
.fi-margin-referral-program:before {
	content: "\f147";
}
.fi-margin-trading:before {
	content: "\f148";
}
.fi-menu-candles:before {
	content: "\f149";
}
.fi-minus-circle:before {
	content: "\f14a";
}
.fi-mobile:before {
	content: "\f14b";
}
.fi-mt-deposit:before {
	content: "\f14c";
}
.fi-mt-payments-history:before {
	content: "\f14d";
}
.fi-mt-referral-ic4:before {
	content: "\f14e";
}
.fi-mt-referral-ic5:before {
	content: "\f14f";
}
.fi-mt-referral-ic6:before {
	content: "\f150";
}
.fi-mt-withdraw:before {
	content: "\f151";
}
.fi-network:before {
	content: "\f152";
}
.fi-online-support:before {
	content: "\f153";
}
.fi-personal-wallet:before {
	content: "\f154";
}
.fi-phone:before {
	content: "\f155";
}
.fi-plus-circle-light:before {
	content: "\f156";
}
.fi-plus-circle:before {
	content: "\f157";
}
.fi-plus-square:before {
	content: "\f158";
}
.fi-promo_landing_4:before {
	content: "\f159";
}
.fi-promo_landing_5:before {
	content: "\f15a";
}
.fi-promo_landing_6:before {
	content: "\f15b";
}
.fi-promo_landing_7:before {
	content: "\f15c";
}
.fi-promo-materials:before {
	content: "\f15d";
}
.fi-qrcode:before {
	content: "\f15e";
}
.fi-referral-program:before {
	content: "\f15f";
}
.fi-referral:before {
	content: "\f160";
}
.fi-referralprogram-adv-1:before {
	content: "\f161";
}
.fi-referralprogram-adv-2:before {
	content: "\f162";
}
.fi-referralprogram-adv-3:before {
	content: "\f163";
}
.fi-referralprogram-adv-4:before {
	content: "\f164";
}
.fi-refresh:before {
	content: "\f165";
}
.fi-regional-partners-1:before {
	content: "\f166";
}
.fi-regional-partners-2:before {
	content: "\f167";
}
.fi-regional-partners-3:before {
	content: "\f168";
}
.fi-regional-partners-4:before {
	content: "\f169";
}
.fi-regional-partners-5:before {
	content: "\f16a";
}
.fi-regional-partners-6:before {
	content: "\f16b";
}
.fi-regional-partners-7:before {
	content: "\f16c";
}
.fi-regional-partners-expectations:before {
	content: "\f16d";
}
.fi-resend-msg:before {
	content: "\f16e";
}
.fi-safe:before {
	content: "\f16f";
}
.fi-search-bold:before {
	content: "\f170";
}
.fi-search:before {
	content: "\f171";
}
.fi-sessions-activities:before {
	content: "\f172";
}
.fi-settings:before {
	content: "\f173";
}
.fi-start_trade:before {
	content: "\f174";
}
.fi-statistics:before {
	content: "\f175";
}
.fi-telegram:before {
	content: "\f176";
}
.fi-terminal:before {
	content: "\f177";
}
.fi-times:before {
	content: "\f178";
}
.fi-trading_platforms-1:before {
	content: "\f179";
}
.fi-trading_platforms-2:before {
	content: "\f17a";
}
.fi-trading_platforms-3:before {
	content: "\f17b";
}
.fi-trading_platforms-4:before {
	content: "\f17c";
}
.fi-trading_robots-1:before {
	content: "\f17d";
}
.fi-trading_robots-2:before {
	content: "\f17e";
}
.fi-trading_robots-3:before {
	content: "\f17f";
}
.fi-trading_signals-1:before {
	content: "\f180";
}
.fi-trading_signals-2:before {
	content: "\f181";
}
.fi-trading_signals-3:before {
	content: "\f182";
}
.fi-trading_signals-4:before {
	content: "\f183";
}
.fi-trading_signals-5:before {
	content: "\f184";
}
.fi-trading_signals-6:before {
	content: "\f185";
}
.fi-trading_signals-7:before {
	content: "\f186";
}
.fi-trading_signals-8:before {
	content: "\f187";
}
.fi-trading-robots:before {
	content: "\f188";
}
.fi-trading-terminals:before {
	content: "\f189";
}
.fi-trading:before {
	content: "\f18a";
}
.fi-twitter:before {
	content: "\f18b";
}
.fi-wallet-main:before {
	content: "\f18c";
}
.fi-wallet:before {
	content: "\f18d";
}
.fi-web_terminal:before {
	content: "\f18e";
}
.fi-web:before {
	content: "\f18f";
}
.fi-windows:before {
	content: "\f190";
}
.fi-withdrawal-success:before {
	content: "\f191";
}
.fi-withdrawal:before {
	content: "\f192";
}
.fi-withdrawals:before {
	content: "\f193";
}
