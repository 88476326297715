@import (reference) "_helpers.less";
@import (reference) "_breakpoint.less";

.loader {
    display: none;
    z-index: 990;

    &:before {
        content: '';
        position: fixed;
        left: ~"calc(50% - 25px)";
        top: 30%;
        z-index: 1000;
        border: 6px solid #fff;
        border-top: 6px solid transparent;
        border-radius: 50%;
        width: 50px;
        height: 50px;
        animation: spin 2s linear infinite;
    }

    &:after {
        content: '';
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: #fff;
        opacity: 0.35;
        z-index: 990;
    }
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}


.loader-inline {
    display: inline-block;
    margin: 0 2px;
    top: 3px;
    width: 1.4em;
    height: 1.4em;
    position: relative;
    background: url(/i/loader_30.svg) no-repeat center;
    background-size: contain;
}

.ld-ring {
    width: 16px;
    height: 16px;
    position: relative;
    color: @color-primary;
    display: inline-block
}

.ld-ring:after {
    width: 100%;
    height: 100%;
    position: absolute;
    margin: auto;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    content: " ";
    display: inline-block;
    background: center center no-repeat;
    background-size: cover;
    border-radius: 50%;
    border-style: solid;
    -webkit-background-clip: padding-box;
    box-sizing: border-box;
    border-width: .15em;
    border-color: currentColor currentColor currentColor transparent;
}

@keyframes ld-spin {
    0% {
        -webkit-transform: rotate(0);
        transform: rotate(0);
        animation-timing-function: cubic-bezier(.55,.055,.675,.19)
    }

    50% {
        -webkit-transform: rotate(180deg);
        transform: rotate(180deg);
        animation-timing-function: cubic-bezier(.215,.61,.355,1)
    }

    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg)
    }
}

@-webkit-keyframes ld-spin {
    0% {
        -webkit-transform: rotate(0);
        transform: rotate(0);
        animation-timing-function: cubic-bezier(.55,.055,.675,.19)
    }

    50% {
        -webkit-transform: rotate(180deg);
        transform: rotate(180deg);
        animation-timing-function: cubic-bezier(.215,.61,.355,1)
    }

    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg)
    }
}

.ld.ld-spin {
    -webkit-animation: ld-spin 1s infinite;
    animation: ld-spin 1s infinite
}

.ld-over {
    position: relative;
    transition: all .2s;
    transition-timing-function: ease-in;
    overflow: hidden
}

.ld-over > .ld {
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -7px;
    opacity: 0;
    z-index: -100;
    transition: all .3s;
    transition-timing-function: ease-in;
}

.ld-over.running > .ld {
    opacity: 1;
    z-index: 999999;
}

.ld-over::before {
    content: " ";
    display: block;
    opacity: 0;
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: all .3s;
    transition-timing-function: ease-in;
    background: rgba(255, 255, 255, 0.6);
}

.ld-over.running::before {
    opacity: 1;
    z-index: 999998;
    display: block;
}

.btn.running.disabled,
.btn.running:disabled {
    opacity: 1;
}
