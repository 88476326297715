.notice {
    padding: 14px 22px;
    background-color: #fdf9ea;
    color: #777056;
    font: normal 1rem/1.85rem "Noto Sans", "Source Sans Pro", sans-serif;

    p {
        margin-bottom: 2rem;

        &:last-child {
            margin-bottom: 0.5rem;
        }
    }
}
