@import '_breakpoint.less';
@import '_helpers.less';
@import '_button.less';
@import '_datepicker-trigger.less';

@import './alerts.less';
@import './notice.less';
@import './header.less';
@import './footer.less';
@import './loader.less';
@import './custom-select.less';
@import './main-banner.less';

//div[class*=col-] {
//    box-shadow: 0 0 0 1px #000;
//}

html {
    .lg-mf({
        overflow-y: scroll;
    });
}

body {
    color: @color-default;
    font-family: "Montserrat", sans-serif;
}

.page {
    display: flex;
    flex-direction: column;
    min-height: 100vh;

    &_type {
        &_service {
            background: #172428;
        }

        &_inner {
            .content {
                padding: 2rem 0;

                .page-caption {
                    padding-top: 0;
                }
            }
        }
    }

    &-header {
        padding: 0;

        &-title {
            font-size: 30px;
            line-height: 36px;
            font-weight: 500;
            color: #161f23;
            padding: 0;
            margin: 0;
            width: 100%;
        }
        &-subtitle {
            font-size: 16px;
            line-height: 24px;
            width: 100%;
        }

        .page-caption {
            padding: 20px 0;
            display: flex;
            align-items: center;
            align-content: center;
            flex-wrap: wrap;
        }

        &.page-header-bg {
            .page-caption {
                padding: 30px 0;
            }
        }

        .md-mf({
            &-title {
                font-size: 38px;
                line-height: 44px;
            }
            &-subtitle {
                font-size: 18px;
                line-height: 30px;
                width: 100%;
            }

            .page-caption {
                padding: 30px 0;
            }

            &.page-header-bg {
                .page-caption {
                    padding: 50px 0;
                    min-height: 380px;
                }
            }
        });
    }

    &__datepicker {
        .vdp-datepicker__calendar {
            .cell {
                &.selected {
                    background-color: #43caab;
                    color: @color-white;
                }

                &:not(.blank):not(.disabled).day:hover,
                &:not(.blank):not(.disabled).month:hover,
                &:not(.blank):not(.disabled).year:hover {
                    border: 1px solid #43caab;
                }
            }
        }
    }

    .input-group {
        .page__datepicker {
            position: relative;
            -ms-flex: 1 1 auto;
            flex: 1 1 auto;
            width: 1%;
            margin-bottom: 0;
        }
    }
}

.page-navigation {
    display: flex;
    margin-bottom: 60px;

    .sm({
        margin-bottom: 40px;
    });

    &__title {
        margin: 0;
        font-size: 32px;
    }

    &__link {
        height: 32px;
        margin: 6px 15px 0 0;
        padding-right: 12px;
    }
}

.icon {
    display: inline-block;

    &_type_arrow-left {
        width: 36px;
        height: 32px;
        background: url(/i/icon-arrow-left.svg) no-repeat center;
    }
}

.fi {
    margin-right: .2em;
    margin-left: .2em;
    transform: translateY(4px);

    &::before {
        text-align: center;
        display: inline-block;
        line-height: 1em;
        font-variant: normal;
        -webkit-font-smoothing: antialiased;
    }

    &-reset-transform {
        transform: translateY(0);
    }
}

.no-margin {
    label {
        margin: 0;

        &:after,
        &:before {
            margin-top: -10px;
        }
    }
}

.content {
    flex: 1;
}

.loader {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: rgba(255,255,255,.65);
    z-index: 1040;
}


/* Tables */
table {
    color: @color-default;
}

.table thead {
    background-color: @color-bg-default;
}

.table thead th {
    color: @color-blue-dark;
}

.table-cell-wrapper {
    max-width: 100px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.table-cell-wrapper-md {
    .md({
        max-width: 100px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    });
}
/* /Tables */

.badge-text {
    &.badge-success {
        background-color: #63c130;
    }

    &.badge-warning {
        background-color: #f8aa37;
    }

    &.badge-danger {
        background-color: #fc1e5f;
    }
}


/* Custom bootstrap select */
.bootstrap-select.dropup,
.bootstrap-select.dropdown,
.bootstrap-select {
    button.btn {
        background-color: #ffffff;
        border: 1px solid #eeeeee;
        border-radius: 4px;
        height: 40px;
        line-height: 40px;
        padding: 0 20px;
        color: #4f6a75;
        outline: none !important;
        box-shadow: none !important;

        &:not(:disabled):not(.disabled):focus {
            box-shadow: none;
            outline: none;
            background-color: #ffffff;
        }
        &:not(:disabled):not(.disabled):hover,
        &:not(:disabled):not(.disabled):active {
            color: #4f6a75;
            border-color: #e5e5e5;
            box-shadow: none;
            background: #ffffff;
            outline: none;
        }

        .filter-option-inner {
            font-size: 14px;
            font-weight: 400;
            color: #4f6a75;
        }
    }

    &.show {
        button.btn {
            color: #4f6a75;
            border-color: #e5e5e5;
            box-shadow: none;
            background: #ffffff;
            outline: none;
        }
    }
}

.add-info-wrapper {
    background-color: #e5eaec;
    padding: 60px 0;
    color: #70889b;
}

.mh100vh {
    min-height: 100vh;
}


.dropdown-item {
    &[aria-expanded="false"] {
        .aria-expanded-true {
            display: none;
        }
        .aria-expanded-false {
            display: block;
        }
    }

    &[aria-expanded="true"] {
        .aria-expanded-true {
            display: block;
        }
        .aria-expanded-false {
            display: none;
        }
    }
}

.counter-list {
    li {
        list-style-type: decimal;
    }
}

.marker-list {
    display: block;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 40px;

    li {
        list-style-type: disc;
    }
}

.go-top {
    bottom: 5rem;
}

/* BUTTONS */
.btn.btn-lg:not(:disabled),
.btn.btn-lg {
    .md-mf({
        padding-top: 20px;
        padding-bottom: 20px;
        padding-right: 30px;
        padding-left: 30px;
        font-size: 18px;
        border-radius: 34px;
    });
}

.btn-gray {
    background: #f1f1f1;
    color: #626262;

    &:hover:not(:disabled),
    &:active:not(:disabled) {
        background: #e5e5e5;
        color: #626262;
    }
}

.btn-paging {
    width: 35px;
    padding: 8px 1px;
    color: @color-default;
    border: 1px solid #B6CBD2;
    border-radius: 5px;
    background-color: #fff;
    font-size: 13px;
    font-weight: 400!important;

    &:hover {
        background-color: @color-bg-default;
    }

    &,
    &:active,
    &:focus {
        background-color: #fff;
    }

    &[disabled] {
        background-color: @color-bg-default;
    }
}

.btn.btn-gradient-04 {
    &_type {
        &_shaddow {
            &:not(.btn-lg) {
                font-size: 1rem;
                padding: 14px 22px;
                box-shadow: 0 13px 23px rgba(78, 207, 164, 0.3);
            }

            &.btn-lg {
                box-shadow: 0 17px 25px rgba(78, 207, 164, 0.3);
            }
        }
    }

    &:active:not(:disabled) {
        color: #ffffff;
        background: linear-gradient(to right, #41c7af 0%, #54e38e 100%);
        background-position: right center;
        border: none;
    }

    .input-group .input-group-btn & {
        padding: 1rem 1.07rem;
        border-radius: 0 !important;
        border-top-left-radius: 4px !important;
        border-bottom-left-radius: 4px !important;
    }
    .input-group input + .input-group-btn & {
        border-radius: 0 !important;
        border-top-right-radius: 4px !important;
        border-bottom-right-radius: 4px !important;
    }
}

.btn.btn-gradient-03 {
    &:focus,
    &:active {
        background-image: linear-gradient(to right, #6859ea 0%, #6acbe0 100%) !important;
        background-position: center center;
        color: #ffffff;
        border-color: #6acbe0;
    }
}

.btn-icon {
    border-radius: 50%;
    padding: 8px;
    width: 38px;
    height: 38px;

    i {
        margin: 0;
        padding: 0;
        line-height: 0.85rem;
        position: relative;
        top: -3px;
    }
}

.btn-outline-purple {
    color: #fff;
    border-color: #8880f4;
    background-color: transparent;
    transition: all 0.2s;

    &:hover,
    &:focus,
    &:active {
        background-color: #8880f4;
        border-color: transparent;
    }
}

.btn-outline-pink {
    color: #fff;
    border-color: #e23f83;
    background-color: transparent;
    transition: all 0.2s;

    &:hover,
    &:active {
        color: #fff;
        background-color: hsl(335, 54%, 50%);
        border-color: transparent;
    }
    &:focus {
        color: #fff;
        border-color: hsl(335, 54%, 50%);
    }
}

.btn-dark {
    &.btn-outline-secondary {
        color: #fff;

        &:hover:not(:disabled),
        &:active:not(:disabled) {
            background-color: #fff;
            border-color: #fff;
            color: #000000;
        }
    }

    &.btn-outline-success {
        color: #fff;

        &:hover:not(:disabled),
        &:active:not(:disabled) {
            color: #fff;
        }
    }

    &.btn-outline-purple {
        color: #fff;

        &:hover:not(:disabled),
        &:active:not(:disabled) {
            background-color: #8880f4;
            border-color: #8880f4;
            color: #fff;
        }
    }
}
/* /BUTTONS */

.row.v-divider {
    [class*='col-']:not(:last-child):after {
        background: #e0e0e0;
        width: 1px;
        content: "";
        display:block;
        position: absolute;
        top:0;
        bottom: 0;
        right: 0;
        min-height: 70px;
    }

    [class*='col-md-4']:not(:last-child):after,
    [class*='col-md-5']:not(:last-child):after,
    [class*='col-md-6']:not(:last-child):after {
        .sm({
            display: none;
        });
    }
}

/* Custom styled-radio */
.styled-radio label:after {
    left: 4px;
    width: 12px;
    height: 12px;
    margin: -11px 0 0;
    background: #50d1a3;
}
/* Custom styled-radio */

.account-page-bg {
    background-color: #f9f9f9;
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-image: url(/i/auth-bg-v4.png);

    .widget.has-shadow {
        box-shadow: 0 0 42.33px 8.67px rgba(0, 0, 0, 0.04);
        border-radius: 4px;

        .widget-header {
            border-radius: 4px 4px 0 0;
        }
    }
}

.action-wrapper {
    font-weight: bold;
    padding-top: 20px;

    ol li {
        list-style-type: decimal;
    }
}


.action-doge-b {
    display: block;
    height: 60px;
    background-color: #5d6df4;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: none;
    width: 100%;

    .swiper-slide-active & {
        background-image: url(/i/action_doge_b.gif);
    }

    &_type {
        &_account {
            background-image: url(/i/action_doge_b.jpg);
        }
    }

    .lg({
        height: 50px;
    });

    .md({
        height: 37px;
    });

    .sm({
        height: 27px;
    });

    .xs({
        background-image: url(/i/action_doge_b_m.jpg) !important;
        background-size: auto 100%;
        height: 100px;
    });
}
.action-lendabit-deal {
    display: block;
    height: 60px;
    background-color: #5059ac;
    background-position: center center;
    background-repeat: no-repeat;
    background-image: none;
    background-size: cover;
    width: 100%;

    .swiper-slide-active & {
        background-image: url(/i/action_lendabit_deal-2.gif);
    }

    .lg({
        height: 50px;
    });

    .md({
        height: 37px;
    });

    .sm({
        height: 27px;
    });

    .xs({
        background-image: url(/i/action_lendabit_deal_m.jpg) !important;
        background-size: auto 100%;
        height: 100px;
    });
}

.action-status-label {
    display: inline-block;
    font-size: 13px;
    font-weight: bold;
    color: @color-white;
    line-height: 38px;
    height: 38px;
    padding: 0 14px;
    border-radius: 19px;

    &_type {
        &_processing {
            background: #ffba00;
        }

        &_transferred {
            background: #5d72ef;
        }

        &_declined {
            background: #e23f83;
        }
    }
}

.action-link {
    position: relative;

    &__label {
        position: absolute;
        color: @color-white;
        font-size: 10px;
        text-transform: uppercase;
        display: block;
        background: #5d72ef;
        line-height: 18px;
        height: 18px;
        padding: 0 4px;
        border-radius: 3px;
        top: -6px;
        right: -25px;
        z-index: 100;

        .sm({
            right: 5px;
        });
    }
}


.styled-checkbox {
    @bl: ~".styled-checkbox";

    label {
        color: #2b4149;

        &::after {
            color: @color-border;
        }
    }

    &_type {
        &_right {
            padding-left: 0;
            padding-right: 25px;

            label {
                &::before {
                    right: 0;
                    left: initial;
                }

                &::after {
                    right: 5px;
                    left: initial;
                }
            }
        }
    }

    &_size {
        &_small {
            padding-left: 18px;

            label {
                &::before {
                    width: 15px;
                    height: 15px;
                    margin-top: -6px;
                }

                &::after {
                    width: 15px;
                    height: 15px;
                    margin-top: -9px;
                    left: 3px;
                }
            }

            &@{bl}_type_right {
                padding-right: 20px;
                padding-left: 0;

                label {
                    &::after {
                        right: -2px;
                        left: initial;
                        margin-top: -8px;
                    }
                }
            }
        }
    }
}


.form-control,
select.form-control,
textarea.form-control,
input.form-control {
    font-size: 14px;
    color: #2b4149;
    transition: all 0.2s linear;

    &:focus {
        border-color: @color-border;
        outline: 0;
        box-shadow: 0 0 0 0.2rem fade( @color-border, 30% ) !important;

        &.is-invalid {
            border-color: @color-error;
            box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25) !important;
        }
    }

    &::-webkit-input-placeholder {
        color: @color-default;
    }
    &::-moz-placeholder {
        color: @color-default;
    }
    &:-ms-input-placeholder {
        color: @color-default;
    }
    &:-moz-placeholder {
        color: @color-default;
    }
}

select.form-control:not([size]):not([multiple]) {
    height: ~"calc(2.715rem + 2px)";
}

.styled-checkbox input[type="checkbox"]:disabled {
    & ~ label {
        opacity: 0.6;
    }
}

.la.la-arrow-left.arrow-back {
    position: relative;
    top: 3px;
    height: 10px;
    width: 35px;
    font-size: 25px;
    line-height: 10px;
    font-weight: bold;
    color: #bababa;
}

.spinner {
    border-top-color: @color-light-gray;

    &_size {
        &_medium {
            width: 25px;
            height: 25px;
            border-width: 3px;
        }

        &_small {
            width: 1rem;
            height: 1rem;
            border-width: 2px;
        }
    }
}

.grecaptcha-badge {
    z-index: 99;
}

.text-overflow {
    overflow: hidden !important;
    text-overflow: ellipsis !important;
}

.read-more {
    display: none;

    &__button {
        color: @color-light-gray;
        text-decoration: underline dotted;
        cursor: pointer;
    }
}

.popover {
    max-width: 320px;
}

.tooltip-inner {
    border-radius: 6px;
}


.dataTables_wrapper {
    position: relative;
}

.page {
    .dataTables_processing {
        border: 0;
    }
}

.wizard-area {
    .dp__input {
        line-height: 1.25;
        border: 1px solid #eee;
        border-radius: 4px;
        padding: 0.75rem 1.07rem 0.75rem 35px;

        &::placeholder {
            color: #2b4149;
        }
    }
}